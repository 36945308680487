class Header {
    constructor(el)
    {
        this.el = el
        this.toggles = this.el.querySelectorAll('.js-header__toggle');

        this.toggles.forEach(toggle => {
            toggle.addEventListener('click', (e) => {
                this.toggleMenu()
            })
        })

        this.onScroll(this.el);
    }

    toggleMenu()
    {
        this.el.classList.toggle('is-active')
    }

    onScroll(el)
    {
        window.addEventListener("scroll", function () {

            if (window.scrollY > 100) {
                el.classList.add('is-not-top');
            } else {
                el.classList.remove('is-not-top');
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelector('.js-header')
    if (el) {
        new Header(el);
    }
});
