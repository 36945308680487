import Swiper from "swiper";
import {Navigation, Pagination, Grid} from "swiper/modules";

class SingleProduct {
    constructor() {
        this.sliderEl = document.querySelector('.woocommerce-product-gallery')

        if (this.sliderEl) {
            this.swiper = new Swiper(this.sliderEl, {
                modules: [Navigation],
                // pagination: {
                //     el: ".js-ingredients-slider__pagination",
                //     bulletClass: this.el.dataset.paginationBulletClass ?? '',
                //     bulletActiveClass: 'is-active',
                // },
                breakpoints: {
                }
            })
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('body').classList.contains('single-product')) {
        new SingleProduct();
    }
});