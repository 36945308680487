import {importAll} from "./helpers/importAll";

importAll(require.context('../img', false, /\.(png|jpe?g|svg)$/));
importAll(require.context('../img/core', false, /\.(png|jpe?g|svg)$/));
importAll(require.context('../../../components/core', true, /\.(js)$/));


importAll(require.context('./helpers', true, /\.(js)$/));

importAll(require.context('./../../../components/blocks-simple', true, /\.(js)$/));

importAll(require.context('./../../../components/blocks', true, /\.(js)$/));

importAll(require.context('./../../../components/woocommerce', true, /\.(js)$/));
