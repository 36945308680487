import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";

class ReviewsSlider {
    constructor(element) {
        this.el = element
        this.sliderEl = this.el.querySelector('.js-fading-images-with-content-slider__slider')


        this.swiper = new Swiper(this.sliderEl, {
            modules: [Navigation, Pagination],
            spaceBetween: 24,
            centeredSlides: true,
            autoHeight: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            on: {
                slideChange: function(sw){
                    sw.hostEl.querySelector('.js-fading-images-with-content-slider__pagination-current').innerHTML = this.realIndex + 1

                    document.querySelector('.js-fading-images-with-content-slider__image--active').classList.remove('js-fading-images-with-content-slider__image--active')
                    document.querySelector('.js-fading-images-with-content-slider__image--' + (this.realIndex + 1)).classList.add('js-fading-images-with-content-slider__image--active')
                }
            }
            
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const reviewsSlider = document.querySelector('.js-fading-images-with-content-slider');
    if (reviewsSlider) {
        new ReviewsSlider(reviewsSlider);
    }
});