import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";

class ReviewsSlider {
    constructor(element) {
        this.el = element
        this.sliderEl = this.el.querySelector('.js-reviews-slider__slider')

        this.swiper = new Swiper(this.sliderEl, {
            modules: [Navigation, Pagination],
            spaceBetween: 16,
            centeredSlides: true,
            initialSlide: 1,
            pagination: {
                el: ".js-reviews-slider__pagination",
                bulletClass: this.el.dataset.paginationBulletClass ?? '',
                bulletActiveClass: 'is-active',
                clickable: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
              },
            breakpoints: {
                768: {
                    grid: {
                        rows: 1
                    },
                    slidesPerView: 3
                },
                992: {
                    grid: {
                        rows: 1
                    },
                    slidesPerView: 3,
                    spaceBetween: 32
                }
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const reviewsSlider = document.querySelector('.js-reviews-slider');
    if (reviewsSlider) {
        new ReviewsSlider(reviewsSlider);
    }
});