class Checkout {
    constructor() {
        this.el = document.querySelector('.wc-checkout')
        this.form = this.el.querySelector('form.checkout')
        this.submit = this.el.querySelector('#place_order')

        this.inputPhone = this.el.querySelector('#billing_phone')
        this.inputFirstName = this.el.querySelector('#billing_first_name')
        this.inputLastName = this.el.querySelector('#billing_last_name')
        this.inputAddress = this.el.querySelector('#billing_address_1')
        this.inputPostcode = this.el.querySelector('#billing_postcode')
        this.inputCity = this.el.querySelector('#billing_city')

        this.form.addEventListener('submit', (e) => {
            if (!this.el.classList.contains('is-extended')) {
                e.preventDefault()

                setTimeout(() => {
                    this.submit.innerText = this.submit.dataset.value
                }, 300)

                
                if (ajaxurl && this.inputPhone.value !== '') {
                    this.el.classList.add('is-extending')
                    setTimeout(() => {
                        this.el.classList.add('is-extended')
                    }, 300)

                    const params = new URLSearchParams({
                        action: 'checkPhoneNumber',
                        phoneNumber: this.inputPhone.value
                    });
                    const Request = new XMLHttpRequest();
                    Request.open("POST", ajaxurl + '?' + params.toString());
                    Request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

                    Request.onreadystatechange = (e) => {
                        if (Request.readyState === 4) {
                            if (Request.status === 200) {
                                if (Request.responseText) {
                                    const foundData = JSON.parse(Request.responseText);
                                    console.log(foundData)
                                    this.inputFirstName.value = foundData.first_name;
                                    this.inputLastName.value = foundData.last_name;
                                    this.inputAddress.value = foundData.address;
                                    this.inputPostcode.value = foundData.postcode;
                                    this.inputCity.value = foundData.city;

                                    setTimeout(() => {
                                        this.el.classList.remove('is-extending')
                                    }, 10)
                                } else {
                                    setTimeout(() => {
                                        this.el.classList.remove('is-extending')
                                    }, 10)
                                }
                            } else {
                                setTimeout(() => {
                                    this.el.classList.remove('is-extending')
                                }, 10)
                            }
                        }
                    }

                    Request.onerror = () => {
                        setTimeout(() => {
                            this.el.classList.remove('is-extending')
                        }, 10)
                    }

                    Request.send();
                } else {
                    this.el.classList.add('is-extending')
                    setTimeout(() => {
                        this.el.classList.add('is-extended')
                        setTimeout(() => {
                            this.el.classList.remove('is-extending')
                        }, 10)
                    }, 300)
                }

            } else {
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('body').classList.contains('woocommerce-checkout')) {
        new Checkout();
    }
});